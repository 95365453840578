import React, { Fragment, useEffect } from "react";
import { info } from "../util/careearinfo";
import { useNavigate } from "react-router-dom";
import Clock from "../../images/icons/time.png";
import location from "../../images/icons/location.png";
import work from "../../images/icons/office.png";
import groupPhoto from "../../images/banner/IMG_6115.png";
import arrowRight from "../../images/icons/rightArrow.png";
import midDesign from "../../images/banner/MidDesign.png";
import AOS from "aos";
import { useMyContext } from "../util/context";

function CareersPage({setActiveHeader}) {
  const navigate = useNavigate();
  const { setCareerActive } = useMyContext();

  // Scroll to the top of the page when the component is mounted
  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init();
  }, []);

  const handleCareer = (id, header) => {
    const encodedHeader = header.replace(/ /g, `-`);
    navigate({
      pathname: `/careersdetail/${encodedHeader}/${id}`,
      state: { header },
    });
    setCareerActive('careers')
  };

  return (
    <Fragment>
      <div data-aos="fade-up" data-aos-duration="1000" className="designPosition">
        <div className="midDesign">
        <img src={midDesign} alt="midDesign"  />
        </div>

        <div className="container mb-5">
          <div className="col-lg-12">
            <h3 className="portfolioCenter">Why Join Us</h3>
            <div className="row align-items-center px-lg-3 gx-sm-5 py-3 px-3">
              <div className="col-lg-6  px-sm-2">
                <img src={groupPhoto} alt="Group" />
              </div>
              <div className="col-lg-6 p-0 px-sm-4">
                <ul className="joinPoints">
                  <li>
                    <strong>Innovative Environment:</strong> Be part of a
                    forward-thinking team that values creativity and innovation.
                    Work on cutting-edge projects that push the boundaries of
                    mobile gaming and make a real impact in the industry.
                  </li>
                  <li>
                    <strong>Professional Growth:</strong> We are committed to
                    your continuous learning and career advancement. Enjoy
                    numerous opportunities for professional development,
                    including workshops, training sessions, and conferences.
                  </li>
                  <li>
                    <strong>Collaborative Culture:</strong> Join a diverse and
                    inclusive team where collaboration and teamwork are at the
                    core of our success. We believe in the power of collective
                    intelligence and encourage open communication and
                    idea-sharing.{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <h3 className="portfolioCenter mb-3">Careers</h3>
        <div className="careearFlex container  ballLightCareers">
          {info.map((info) => {
            const initialDate = new Date(info.initialDate);
            const now = new Date();
            const daysPassed = Math.floor(
              (now - initialDate) / (1000 * 60 * 60 * 24)
            );
            const displayDate =
              daysPassed === 0
                ? "Today"
                : `${daysPassed} ${daysPassed === 1 ? "day" : "days"}`;

            return (
              <div
                className="heading"
                key={info.jobid}
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h4>{info.header}</h4>
                <div className="d-flex">
                  <div className="d-flex subContent">
                    <div>
                      <img
                        src={Clock}
                        alt="Charles Technologies"
                        className="cardImageFour"
                      />
                    </div>
                    <div className="subHeading">{displayDate}</div>
                  </div>

                  <div className="d-flex subContent">
                    <div>
                      <img
                        src={location}
                        alt="Charles Technologies"
                        className="cardImageFour"
                      />
                    </div>
                    <div className="subHeading">{info.location}</div>
                  </div>

                  <div className="d-flex subContent">
                    <div>
                      <img
                        src={work}
                        alt="Charles Technologies"
                        className="cardImageFour"
                      />
                    </div>
                    <div className="subHeading">{info.workHome}</div>
                  </div>
                </div>
                <div className="d-flex flex-column cardFlex">
                  <div>
                    <p>
                      <strong>Qualification:</strong> {info.qual}{" "}
                    </p>
                    <p>
                      <strong>Experience:</strong> {info.exp}{" "}
                    </p>
                    <p>
                      <strong>Skills:</strong> {info.skil}{" "}
                    </p>
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={() => handleCareer(info.jobid, info.header)}
                    >
                      Read More <img src={arrowRight} alt="arrow" />
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Fragment>
  );
}

export default CareersPage;
