import React, { Fragment } from "react";
import roundTube from "../../images/banner/roundTube.png";
import sprial from "../../images/banner/Sprial.png";
import CGLogo from "../../images/logo/CGFadeLogo.png";
import CGNameCircle from "../../images/logo/CompanyNameCircle.png";
import Spacing from "../Spacing";
//import  Spline  from "react-spline";
//import Spline from '@splinetool/react-spline';

function LandingPage() {
  return (
    <Fragment>
      <div className="container ballLightLeft ballLightRight">
        <div className="landingWrap">
          <div>
            <h1>
              Welcome to <br /> Charles Technologies!
            </h1>
            <Spacing lg="40" md="40" />
            <h3>Innovating Tomorrow, Today.</h3>
            <p>
              Where innovation meets excitement in the heart of Chennai! As a
              fast-growing and dynamic startup, we are passionate about
              transforming the mobile gaming landscape with our user-centric
              games that turn everyday moments into extraordinary adventures.
            </p>
          </div>
          <div className="landRight">
          {/* <Spline scene="https://prod.spline.design/1nvqLWEZvSF3ODHA/scene.splinecode" /> */}
            <img src={roundTube} className="roundTube" /> 
           <img src={sprial} className="spiral" />
          </div>
        </div>
        <Spacing lg="180" md="40" />
        <div className="companyLogo">
          <img src={CGLogo} className="CGLogo" />
          <img src={CGNameCircle} className="CGNameCircle" />
        </div>
      </div>
    </Fragment>
  );
}

export default LandingPage;
