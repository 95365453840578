import React from "react";

export default function CreativeSolutionHome() {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-sm-4">
            At Charles-Technologies, we work one-on-one with our customers to
            foster better client relationships and gain a deeper understanding
            of their requirements. <br></br> We have highlighted a few of our
            primary services above, with additional services listed alongside.
            We thoroughly understand our customers' needs, and after proper
            documentation of the Software Requirements Specification and
            wireframes, our team begins the project.
          </div>
          <div className="col-md-3 col-sm-4">
            <img src="/images/technology/filters.png" />
          </div>
          <div className="col-md-3 col-sm-4">
            We ensure complete transparency and provide regular updates about
            the ongoing work. Every project requires support and maintenance to
            keep on updatings the UX and to stay up-to-date.
          </div>
        </div>
      </div>
    </>
  );
}
