import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { info } from "../util/careearinfo";
import AOS from "aos";
function CareerDiscriptionPage({careerRef}) {
  const { id } = useParams();
  const job = info.find((job) => job.jobid === id);
  const [activeTab, setActiveTab] = useState("overview");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    resume: null,
  });
  // Scroll to the top of the page when the component is mounted
  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init();
  }, []);
  const [fileName, setFileName] = useState("");

  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: files ? files[0] : value,
    }));
  };

  const applyHandler = () => {
    if (job.linkId) {
      window.open(`https://www.linkedin.com/jobs/view/${job.linkId}`, "_blank");
    } else {
      var subject = encodeURIComponent(
        job.header + " - Career opportunities at Charles Technologies"
      );
      window.open(
        "mailto:kishorekumar.g@charles-technologies.com?subject=" + subject
      );
    }
  };

  const backHandler = () => {
    navigate("/");
  };

  const initial = new Date(job.initialDate);
  const now = new Date();
  const daysPassed = Math.floor((now - initial) / (1000 * 60 * 60 * 24));
  const displayDate =
    daysPassed === 0
      ? "Today"
      : `${daysPassed} ${daysPassed === 1 ? "Day" : "Days"}`;

  return (
    <Fragment>
      <div className="container paddingTop" data-aos="flip-left">
        <div className="tabContent">
          <div className="contentGrid">
            <div className="contentGridLeft">
              <button
                type="button"
                onClick={backHandler}
                className="backButton"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Outline"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path
                    d="M19,11H9l3.29-3.29a1,1,0,0,0,0-1.42,1,1,0,0,0-1.41,0l-4.29,4.3A2,2,0,0,0,6,12H6a2,2,0,0,0,.59,1.4l4.29,4.3a1,1,0,1,0,1.41-1.42L9,13H19a1,1,0,0,0,0-2Z"
                    fill="#fff"
                  />
                </svg>
              </button>
              <div className="contentSection">
                <div className="firstdiv">Date posted</div>
                <div className="seconddiv">{displayDate}</div>
              </div>
              <div className="contentSection">
                <div className="firstdiv">Location</div>
                <p>{job.location}</p>
              </div>
              <div className="contentSection">
                <div className="firstdiv">Reporting To</div>
                <div className="seconddiv">{job.reporting}</div>
              </div>
              <div className="contentSection">
                <div className="firstdiv">Work site</div>
                <div className="seconddiv">{job.workHome}</div>
              </div>
            </div>
            <div className="contentGridRight">
              <h2>{job.header}</h2>
              <div>
                <div>
                  <p className="mb-3">{job.content}</p>
                </div>

                <div className="contentPoints">
                  <h3>Qualifications :</h3>
                  <ul>
                    {job.qualification &&
                      job.qualification.map((point, index) => (
                        <li key={index}>
                          <strong>Education:</strong> {point}
                        </li>
                      ))}
                    {job.requriedexp &&
                      job.requriedexp.map((point, index) => (
                        <li key={index}>
                          <strong>Experience:</strong> {point}
                        </li>
                      ))}
                    {job.foundationalKnowledge &&
                      job.foundationalKnowledge.map((point, index) => (
                        <li key={index}>
                          <strong>Foundational Knowledge:</strong> {point}
                        </li>
                      ))}
                    {job.Qualification &&
                      job.Qualification.map((point, index) => {
                        const strongPhrases = [
                          "Education:",
                          "Experience:",
                          "React Native",
                          "Node.js",
                          "Azure Cosmos DB ",
                          "Agile development",
                          "Azure DevOps",
                          "CI/CD Pipelines",
                          "Azure Cloud Services",
                          "Foundational Knowledge",
                          
                        ];

                        let updatedPoint = point;

                        strongPhrases.forEach((phrase) => {
                          if (point.includes(phrase)) {
                            updatedPoint = point.replace(
                              phrase,
                              `<strong>${phrase}</strong>`
                            );
                          }
                        });

                        return (
                          <li
                            key={index}
                            dangerouslySetInnerHTML={{ __html: updatedPoint }}
                          />
                        );
                      })}
                  </ul>
                </div>

                {job.skills && (
                  <div className="contentPoints">
                    <h3>Technical Skills :</h3>
                    <ul>
                      {job.skills.map((point, index) => {
                        const strongPhrases = [
                          "Roadmap Development:",
                          "User Story Creation: ",
                          "Stakeholder Management:",
                          "Market Research: ",
                          "Data-Driven Decision Making:",
                          "Agile Methodologies: ",
                          "Technical Acumen: ",
                          "Leadership: ",
                          "Communication:",
                          "Team Management:",
                          "Technical Knowledge: ",
                          "Regulatory Compliance: ",
                          "Analytical Skills:",
                          "Strategic Planning:",
                          "Scripting Languages",
                          "Testing Frameworks: ",
                          "Automation Testing: ",
                          "Manual Testing:",
                          "CI/CD Integration: ",
                          "Bug Tracking and Task Management:",
                          "API Testing: ",
                          "Performance Testing: ",
                          "JavaScript:",
                          "React Native:",
                          "React:",
                          "Node.js: ",
                          "Azure Cosmos DB: ",
                          "Azure Cloud Services: ",
                          "Git:",
                          "Azure DevOps:",
                          "API Integration:",
                          "UI/UX Design:",
                          "Backlog Prioritization:",
                          "Technical Demonstrations:",
                          "Customer Analysis: ",
                          "Sales Collaboration: ",
                          "Market Insight: ",
                          "Communication & Problem-Solving:",
                          "Presentation Skills:",
                          "Rapid Execution: ",
                          "Programming Languages:",
                          "Machine Learning Frameworks: ",
                          "Data Visualization: ",
                          "Cloud Services: ",
                          "SQL and Azure Cosmos DB: ",
                          "Version Control:",
                          "Azure Cloud Services: ",
                          "UI/UX Collaboration:",
                          "Facilitation: ",
                          "Conflict Resolution: ",
                          "Coaching: ",
                          "Continuous Improvement: ",
                          
                        ];

                        let updatedPoint = point;

                        strongPhrases.forEach((phrase) => {
                          if (point.includes(phrase)) {
                            updatedPoint = point.replace(
                              phrase,
                              `<strong>${phrase}</strong>`
                            );
                          }
                        });

                        return (
                          <li
                            key={index}
                            dangerouslySetInnerHTML={{ __html: updatedPoint }}
                          />
                        );
                      })}
                    </ul>
                  </div>
                )}
                <div className="contentPoints">
                  <h3>Responsibilities :</h3>
                  <ul>
                    {job.responsePoints &&
                      job.responsePoints.map((point, index) => {
                        const strongPhrases = [
                          "Product Vision:",
                          "Backlog Management: ",
                          "User Stories:",
                          "Sprint Planning:",
                          "Daily Collaborative Development:",
                          "Agile Ceremonies:",
                          "Stakeholder Communication:",
                          "Market Research:",
                          "Quality Assurance:",
                          "Performance Metrics:",
                          "Continuous Improvement:",
                          "Financial Management:",
                          "Strategy Development:",
                          "Budget Management: ",
                          "Compliance: ",
                          "Financial Reporting: ",
                          "Risk Management: ",
                          "Team Leadership: ",
                          "Performance Reviews:",
                          "Technology Integration:",
                          "Trend Analysis: ",
                          "Test Planning:",
                          "Automation Testing: ",
                          "Manual Testing:",
                          "CI/CD Integration:",
                          "Bug Tracking:",
                          "API Testing: ",
                          "Performance Testing: ",
                          "Collaboration: ",
                          "Peer Code Reviews:",
                          "Documentation: ",
                          "Testing and Debugging: ",
                          "Application Development: ",
                          "Backend Development: ",
                          "Database Management: ",
                          "Version Control:",
                          "Performance Optimization:",
                          "Technical Expertise:",
                          "Customer Engagement:",
                          "Sales Support:",
                          "Market Analysis: ",
                          "Testing and Validation: ",
                          "Model Development:",
                          "Visualization:",
                          "Data Management:",
                          "Peer Reviews:",
                          "CI/CD Pipelines:",
                          "Full Stack Development:",
                          "UI/UX Collaboration:",
                          "Agile Facilitation: ",
                          "Impediment Removal: ",
                          "Team Coaching: ",
                          "Source Candidates:",
                          "Conduct Interviews:",
                          "Maintain ATS:",
                          "Employer Branding:",
                          "Onboarding:",
                          "Assess Training Needs:",
                          "Design Training Programs:",
                          "Evaluate Training Effectiveness:",
                          "Career Development:",
                          "Monitor Progress:",
                          "Stay Updated:",
                        ];

                        let updatedPoint = point;

                        strongPhrases.forEach((phrase) => {
                          if (point.includes(phrase)) {
                            updatedPoint = point.replace(
                              phrase,
                              `<strong>${phrase}</strong>`
                            );
                          }
                        });

                        return (
                          <li
                            key={index}
                            dangerouslySetInnerHTML={{ __html: updatedPoint }}
                          />
                        );
                      })}
                  </ul>
                </div>
                <div className="contentPoints">
                  <h3>Perks & Benefits :</h3>
                  <ul>
                    {job.Perks.map((point, index) => {
                      const strongPhrases = [
                        "Central Location:",
                        "Meals and Refreshments:",
                        "Insurance:",
                        "Professional Development:",
                        "Team Outings and Events:",
                        "Employee Recognition:",
                      ];

                      let updatedPoint = point;

                      strongPhrases.forEach((phrase) => {
                        if (point.includes(phrase)) {
                          updatedPoint = point.replace(
                            phrase,
                            `<strong>${phrase}</strong>`
                          );
                        }
                      });

                      return (
                        <li
                          key={index}
                          dangerouslySetInnerHTML={{ __html: updatedPoint }}
                        />
                      );
                    })}
                  </ul>
                </div>
                <div
                  id="apply-link"
                  className="btn applyButton mb-2 mt-1 button"
                  onClick={applyHandler}
                >
                  Apply via LinkedIn
                </div>
              </div>
              <div className="py-5" >
                <h5 className="pb-3">How to Apply</h5>
                Interested candidates can apply through LinkedIn or email us at
                <Link to="mailto:careers@charles-technologies.com">
                  <span className="linkMail">
                    careers@charles-technologies.com
                  </span>
                </Link>{" "}
                Join us at Charles Technologies and be a part of a team that’s
                shaping the future of mobile applications!
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default CareerDiscriptionPage;