import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";

export default function Layout({ headerVariant}) {
  const [careerRef, setCareerRef] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header variant={headerVariant} careerRef={careerRef} />
      <Outlet context={{ setCareerRef }}/>
      <Footer />
    </>
  );
}
