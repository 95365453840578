import { Route, Routes } from "react-router-dom";
import ErrorPage from "./components/Pages/ErrorPage";
import Home from "./components/Pages/Home";
import Layout from "./components/Layout/Layout";
import CreativeSolutionHome from "./components/Pages/CreativeSolutionHome";
import CareerDiscriptionPage from "./components/Pages/CareerDiscriptionPage";
import { MyContextProvider } from "./components/util/context";

function App() {


  return (
    <MyContextProvider>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="creative-solution" element={<CreativeSolutionHome />} />
          <Route  path="/careersdetail/:header/:id" element={ <CareerDiscriptionPage/> } />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </MyContextProvider>
  );
}

export default App;
