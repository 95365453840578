export const info = [
 
  {
    jobid: "1",
    header: "React Native Developer",
    location: "Chennai",
    workHome: "On-Site",
    initialDate: "2024-11-04T00:00:00",
    Discipline: "Technology",
    reporting: "Product Manager",
    linkId: "react-native-developer-at-charles-technologies-4064803316",
    exp: "2 Years",
    qual: "Bachelor’s degree in Computer Science, Information Technology, or a related field.",
    skil: "React Native,React,Node.js ",
    content:
      "Charles Technologies is a dynamic startup based in Chennai, dedicated to creating innovative mobile applications that transform user experiences. We are looking for a talented and experienced React Native Developer to join our team and contribute to the development of cutting-edge mobile applications.",
    requriedexp: [
      "Minimum of 2 years of experience in mobile application development using React Native.",
    ],
    qualification: [
      "Bachelor’s degree in Computer Science, Information Technology, or a related field. Relevant certifications are a plus.",
    ],
    foundationalKnowledge: [
      "Strong understanding of software engineering principles, product development, and mobile application development best practices.",
    ],
    skills: [
      "React Native: Advanced expertise in React Native for developing cross-platform mobile applications, including experience with native modules and performance optimization.",
      "JavaScript: Expert-level proficiency in JavaScript, including ES6+ features. ",
      "React: Proficient in React for front-end development, including hooks, context API, and state management libraries like Redux.",
      "Node.js: Solid knowledge of Node.js for backend development, including experience with Express.js and asynchronous programming.",
      "Azure Cosmos DB: Extensive experience with Azure Cosmos DB for scalable and efficient data management, including partitioning, indexing, and querying.",
      "Azure Cloud Services: Proficiency in deploying and managing applications on Azure Cloud Services, including Azure App Services, Azure Functions, and Azure Storage.",
      "Git: Proficient in version control systems like Git, including branching, merging strategies, and pull request workflows.",
      "Azure DevOps: Experience with Azure DevOps for CI/CD pipelines, project management, and automated testing.",
      "API Integration: Experience in integrating RESTful APIs and third-party services, including authentication and authorization mechanisms.",
      "UI/UX Design: Understanding of UI/UX design principles and ability to collaborate with designers to implement responsive and user-friendly interfaces.",
    ],

    responsePoints: [
      "Application Development: Develop and maintain high-quality mobile applications using React Native and React, ensuring code quality and performance.",
      "Backend Development: Implement backend services and APIs using Node.js, ensuring scalability and security.",
      "Database Management: Manage and optimize databases using Azure Cosmos DB, including data modelling, indexing, and performance tuning.",
      "Version Control: Use Git for version control, including branching, merging, and pull request workflows. Conduct peer code reviews to ensure code quality and share knowledge with team members.",
      "CI/CD Pipelines: Set up and maintain CI/CD pipelines using Azure DevOps, including automated testing, deployment, and monitoring.",
      "Peer Code Reviews: Participate in peer code reviews to ensure adherence to coding standards, identify potential issues, and share best practices.",
      "Performance Optimization: Optimize application performance and ensure responsiveness across different devices and platforms, including profiling and debugging.",
      "Collaboration: Work closely with designers, product owners, and other developers to deliver high-quality applications. Participate in agile development processes, including sprint planning, stand-ups, and retrospectives.",
      "Testing and Debugging: Conduct thorough testing and debugging to ensure the reliability and stability of applications, including unit testing, integration testing, and end-to-end testing.",
      "Documentation: Create and maintain comprehensive documentation for code, APIs, and development processes, including technical specifications and user guides.",
      "Continuous Improvement: Stay updated with the latest industry trends and technologies, and continuously improve development practices. Participate in knowledge-sharing sessions and contribute to the growth of the team.",
    ],

    Perks: [
      "Central Location:  Conveniently located in the heart of the city, with parking facilities, and well-served by public transport including buses and Chennai Metro.",
      "Meals and Refreshments: Lunch, tea/coffee, snacks, and refreshments provided throughout the day.",
      "Insurance: TATA AIG Family Group Insurance for INR 5.0 Lakhs (Coverage: Self + Spouse + Up to 3 Children).",
      "Professional Development: Opportunities for continuous learning and growth.",
      "Team Outings and Events: Regular team-building activities and events.",
      "Employee Recognition: Programs to acknowledge and reward outstanding performance.",
    ],
  },


  {
    jobid: "2",
    header: "Product Owner",
    location: "Chennai",
    workHome: "On-Site",
    initialDate: "2024-11-04T00:00:00",
    Discipline: "Technology",
    reporting: "Product Manager",
    exp: "3 Years",
    qual: "Bachelor’s degree in Computer Science, Information Technology, Business Administration, or a related field.",
    skil: "Roadmap Development,Backlog Prioritization",
    linkId: "product-owner-at-charles-technologies-4064803307",
    content:
      "Charles Technologies is a dynamic startup based in Chennai, dedicated to creating innovative mobile applications that transform user experiences. We are looking for a passionate and experienced Product Owner to join our team and drive the development of our cutting-edge web and mobile applications.",
    requriedexp: [
      " Minimum of 3 years of experience as a Product Owner or in a similar role in product management.",
    ],
    foundationalKnowledge: [
      "Strong understanding of software engineering principles, product development, and agile methodologies.",
    ],
    qualification: [
      "Bachelor’s degree in Computer Science, Information Technology, Business Administration, or a related field. Relevant certifications (e.g., CSPO, PSPO) are a plus.",
    ],
    skills: [
      "Roadmap Development: Ability to create and maintain a product roadmap that aligns with business goals and customer needs.",
      "Backlog Prioritization: Expertise in prioritizing the product backlog based on business value, customer feedback, and technical feasibility.",
      "User Story Creation: Proficiency in writing clear and concise user stories with well-defined acceptance criteria.",
      "Stakeholder Management: Strong skills in managing and communicating with stakeholders at all levels to gather requirements, provide updates, and align on priorities.",
      "Market Research: Experience in conducting market research and competitive analysis to inform product decisions and identify opportunities.",
      "Data-Driven Decision Making: Ability to use data and analytics to make informed product decisions and measure the success of product features.",
      "UI/UX Collaboration: Understanding of UI/UX design principles and ability to collaborate with designers to create user-friendly interfaces.",
      "Agile Methodologies: Extensive experience with agile methodologies, including Scrum and Kanban, and conducting agile ceremonies such as sprint reviews, retrospectives, and daily stand-ups.",
      "Technical Acumen: Basic understanding of web and mobile technologies, including JavaScript, React, Node.js, and cloud services like Azure.",
    ],

    responsePoints: [
      "Product Vision: Define and communicate the product vision and strategy to the development team and stakeholders.",
      "Backlog Management: Create, prioritize, and maintain the product backlog, ensuring alignment with business goals and customer needs.",
      "User Stories: Write clear and concise user stories with well-defined acceptance criteria.",
      "Sprint Planning: Participate in sprint planning meetings to ensure the development team understands the requirements and priorities.",
      "Daily Collaborative Development: Engage in daily stand-ups and collaborative development sessions to ensure alignment and progress.",
      "Agile Ceremonies: Conduct agile ceremonies such as sprint reviews, retrospectives, and daily stand-ups to ensure effective team collaboration and continuous improvement.",
      "Stakeholder Communication: Act as the primary point of contact for stakeholders, providing regular updates on product progress and gathering feedback.",
      "Market Research: Conduct market research and competitive analysis to identify opportunities and inform product decisions.",
      "UI/UX Collaboration: Work closely with designers to create intuitive and user-friendly interfaces.",
      "Quality Assurance: Collaborate with QA engineers to ensure the product meets quality standards and acceptance criteria.",
      "Performance Metrics: Define and track key performance metrics to measure the success of the product.",
      "Continuous Improvement: Continuously evaluate and improve product management processes and tools. Stay updated with the latest industry trends and best practices.",
    ],
    Qualification: [],
    Perks: [
      "Central Location:  Conveniently located in the heart of the city, with parking facilities, and well-served by public transport including buses and Chennai Metro.",
      "Meals and Refreshments: Lunch, tea/coffee, snacks, and refreshments provided throughout the day.",
      "Insurance: TATA AIG Family Group Insurance for INR 5.0 Lakhs (Coverage: Self + Spouse + Up to 3 Children).",
      "Professional Development: Opportunities for continuous learning and growth.",
      "Team Outings and Events: Regular team-building activities and events.",
      "Employee Recognition: Programs to acknowledge and reward outstanding performance.",
    ],
  },

  {
    jobid: "3",
    header: "Quality Assurance Test Engineer",
    location: "Chennai",
    workHome: "On-Site",
    initialDate: "2024-11-04T00:00:00",
    Discipline: "Technology",
    reporting: "Product Manager",
    exp: "3 Years",
    qual: "Bachelor’s degree in Computer Science, Information Technology, or a related field. Relevant certifications (e.g., ISTQB, CSTE) are a plus.",
    skil: "Scripting Languages,Automation Testing",
    linkId:
      "quality-assurance-test-engineer-at-charles-technologies-4064801486",
    content:
      "Charles Technologies is a dynamic startup based in Chennai, dedicated to creating innovative mobile applications that transform user experiences. We are looking for a meticulous and experienced Quality Assurance Test Engineer to join our team and ensure the highest quality standards for our web and mobile applications.",
    requriedexp: [
      "Minimum of 3 years of experience in quality assurance and testing.",
    ],
    qualification: [
      "Bachelor’s degree in Computer Science, Information Technology, or a related field. Relevant certifications (e.g., ISTQB, CSTE) are a plus.",
    ],
    foundationalKnowledge: [
      "Strong understanding of software engineering principles, product development, and quality assurance best practices.",
    ],
    skills: [
      "Scripting Languages: Proficiency in Java for test automation.",
      "Testing Frameworks: Proficiency in using testing frameworks and tools such as Selenium, Appium, JUnit, TestNG, and Postman.",
      "Automation Testing: Experience in developing and maintaining automated test scripts for web and mobile applications.",
      "Manual Testing: Strong skills in manual testing, including functional, regression, integration, and user acceptance testing.",
      "CI/CD Integration: Experience with integrating automated tests into CI/CD pipelines using Azure DevOps.",
      "Bug Tracking and Task Management: Proficiency in using Azure DevOps Boards for bug tracking, test planning, and task management.",
      "API Testing: Experience in testing RESTful APIs using tools like Postman or SoapUI.",
      "Performance Testing: Knowledge of performance testing tools such as JMeter or LoadRunner.",
    ],
    responsePoints: [
      "Test Planning: Develop comprehensive test plans and test cases based on project requirements and specifications using Azure DevOps Boards.",
      "Automation Testing: Design, develop, and maintain automated test scripts for web and mobile applications using tools like Selenium and Appium.",
      "Manual Testing: Conduct thorough manual testing to identify defects and ensure the functionality, usability, and performance of applications.",
      "CI/CD Integration: Integrate automated tests into CI/CD pipelines using Azure DevOps to ensure continuous testing and delivery.",
      "Bug Tracking: Log, track, and manage defects using Azure DevOps Boards, and work closely with developers to resolve issues.",
      "API Testing: Perform API testing to validate the functionality, reliability, and performance of backend services.",
      "Performance Testing: Conduct performance testing to identify bottlenecks and ensure applications meet performance requirements.",
      "Collaboration: Work closely with developers, product owners, and other stakeholders to understand requirements and ensure quality throughout the development lifecycle.",
      "Peer Code Reviews: Participate in peer code reviews to ensure test scripts adhere to coding standards and best practices.",
      "Documentation: Create and maintain comprehensive documentation for test plans, test cases, and test results.",
      "Continuous Improvement: Stay updated with the latest industry trends and technologies, and continuously improve testing practices. Participate in knowledge-sharing sessions and contribute to the growth of the team.",
    ],

    Perks: [
      "Central Location:  Conveniently located in the heart of the city, with parking facilities, and well-served by public transport including buses and Chennai Metro.",
      "Meals and Refreshments: Lunch, tea/coffee, snacks, and refreshments provided throughout the day.",
      "Insurance: TATA AIG Family Group Insurance for INR 5.0 Lakhs (Coverage: Self + Spouse + Up to 3 Children).",
      "Professional Development: Opportunities for continuous learning and growth.",
      "Team Outings and Events: Regular team-building activities and events.",
      "Employee Recognition: Programs to acknowledge and reward outstanding performance.",
    ],
  },

  {
    jobid: "4",
    header: "Full Stack Developer",
    location: "Chennai",
    workHome: "On-Site",
    initialDate: "2024-11-04T00:00:00",
    Discipline: "Technology",
    reporting: "Product Manager",
    exp: "3 Years",
    qual: "Bachelor's degree in Computer Science, Information Technology, or a related field. A Master’s degree is a plus. Relevant certifications are also a plus.",
    skil: "React Native,React,Node.js",
    linkId: "senior-full-stack-developer-at-charles-technologies-4064803307",
    content:
      "Charles Technologies is a dynamic startup based in Chennai, dedicated to creating innovative mobile applications that transform user experiences. We are looking for a talented and experienced MERN Stack Developer to join our team and lead the development of innovative web and mobile applications.",
    requriedexp: [
      "Minimum of 3 years of total experience in full stack application development. Extensive experience working with startups, small teams, and in fast-paced environments is highly desirable.",
    ],
    qualification: [
      "Bachelor's degree in Computer Science, Information Technology, or a related field. A Master’s degree is a plus. Relevant certifications are also a plus. ",
    ],
    foundationalKnowledge: [
      "Strong understanding of software engineering principles, product development, and web/mobile application development best practices. ",
    ],
    skills: [
      "JavaScript: Expert-level proficiency in JavaScript, including ES6+ features, asynchronous programming, and modern frameworks.",
      "React Native: Extensive experience in developing cross-platform mobile applications using React Native, including performance optimization and native module integration. ",
      "React: Advanced expertise in React for front-end development, including hooks, context API, state management libraries like Redux, and component lifecycle management.",
      "Node.js: Solid knowledge of Node.js for backend development, including experience with Express.js, RESTful API design, and asynchronous programming patterns.",
      "Azure Cosmos DB: Extensive experience with Azure Cosmos DB for scalable and efficient data management, including partitioning, indexing, querying, and performance tuning.",
      "Azure Cloud Services: Proficiency in deploying and managing applications on Azure Cloud Services, including Azure App Services, Azure Functions, Azure Storage, and monitoring tools. ",
      "Git: Proficient in version control systems like Git, including branching, merging strategies, pull request workflows, and conflict resolution.",
      "Azure DevOps: Experience with Azure DevOps for CI/CD pipelines, project management, automated testing, and release management.",
      "API Integration: Experience in integrating RESTful APIs and third-party services, including OAuth, JWT, and other authentication and authorization mechanisms.",
      "UI/UX Design: Understanding of UI/UX design principles and ability to collaborate with designers to implement responsive, accessible, and user-friendly interfaces.",
    ],
    responsePoints: [
      "Full Stack Development: Develop and maintain high-quality web and mobile applications using React Native, React, and Node.js, ensuring code quality, performance, and scalability.",
      "Backend Development: Implement backend services and APIs using Node.js, ensuring scalability, security, and maintainability.",
      "Database Management: Manage and optimize databases using Azure Cosmos DB, including data modelling, indexing, partitioning, and performance tuning.",
      "Version Control: Use Git for version control, including branching, merging, and pull request workflows. Conduct peer code reviews to ensure code quality and share knowledge with team members.",
      "CI/CD Pipelines: Set up and maintain CI/CD pipelines using Azure DevOps, including automated testing, deployment, monitoring, and rollback strategies.",
      "Peer Code Reviews: Participate in peer code reviews to ensure adherence to coding standards, identify potential issues, and share best practices.",
      "Performance Optimization: Optimize application performance and ensure responsiveness across different devices and platforms, including profiling, debugging, and performance tuning.",
      "Collaboration: Work closely with designers, product owners, and other developers to deliver high-quality applications. Participate in agile development processes, including sprint planning, stand-ups, and retrospectives.",
      "Testing and Debugging: Conduct thorough testing and debugging to ensure the reliability and stability of applications, including unit testing, integration testing, and end-to-end testing. ",
      "Documentation: Create and maintain comprehensive documentation for code, APIs, and development processes, including technical specifications and user guides.",
      "Continuous Improvement: Stay updated with the latest industry trends and technologies, and continuously improve development practices. Participate in knowledge-sharing sessions and contribute to the growth of the team.",
    ],

    Perks: [
      "Central Location:  Conveniently located in the heart of the city, with parking facilities, and well-served by public transport including buses and Chennai Metro.",
      "Meals and Refreshments: Lunch, tea/coffee, snacks, and refreshments provided throughout the day.",
      "Insurance: TATA AIG Family Group Insurance for INR 5.0 Lakhs (Coverage: Self + Spouse + Up to 3 Children).",
      "Professional Development: Opportunities for continuous learning and growth.",
      "Team Outings and Events: Regular team-building activities and events.",
      "Employee Recognition: Programs to acknowledge and reward outstanding performance.",
    ],
  },

  {
    jobid: "5",
    header: "Machine Learning Engineer",
    location: "Chennai",
    workHome: "On-Site",
    initialDate: "2024-11-04T00:00:00",
    Discipline: "Technology",
    reporting: "Product Manager",
    exp: "3 Years",
    qual: "Bachelor of Engineering in Computer Science, Data Science, AI or Machine Learning. A Master’s degree or relevant certifications are a plus.",
    skil: "Programming Languages,Cloud Services",
    linkId: "machine-learning-engineer-at-charles-technologies-4064805228",
    content:
      "Charles Technologies is a dynamic startup based in Chennai, dedicated to creating innovative mobile applications that transform user experiences. We are looking for a talented and experienced Machine Learning Engineer to join our team and contribute to the development of advanced machine learning solutions that enhance our mobile applications.",
    requriedexp: [
      "Minimum of 3 years of experience in machine learning engineering or a related field. Experience working in an Agile Product Development",
    ],
    qualification: [
      "Bachelor of Engineering in Computer Science, Data Science, AI or Machine Learning. A Master’s degree or relevant certifications are a plus.",
    ],
    foundationalKnowledge: [
      "Strong understanding of machine learning algorithms, software engineering, statistical analysis, and data mining techniques. Knowledge of product development and mobile application development is a plus.",
    ],
    skills: [
      "Programming Languages: Proficiency in Python and JavaScript for developing machine learning models and integrating them with applications.",
      "Machine Learning Frameworks: Experience with libraries and frameworks such as Pandas, Scikit-learn and XGBoost. Experience with Tensorflow.js is a plus. Proficiency in Azure Machine Learning Services.",
      "Data Visualization: Proficiency in JavaScript libraries like Plotly.js and D3.js for visualizing model outputs and data insights.  ",
      "SQL and Azure Cosmos DB: Strong knowledge of SQL for data querying and manipulation. Proficiency in Azure Cosmos DB for scalable and efficient data management.",
      "Cloud Services: Experience with Azure for deploying machine learning models and solutions.",
      "Azure DevOps: Proficiency in Azure DevOps, including Repos, Pipelines, and Kanban boards.",
      "Version Control: Proficient in version control systems like Git.",
      "API Integration: Experience in integrating machine learning models with RESTful APIs and third-party services.",
    ],
    responsePoints: [
      "Model Development: Design, develop, and implement machine learning models to solve business problems and enhance mobile applications.",
      "Data Management: Manage and optimize data storage and retrieval processes, ensuring data quality and integrity.",
      "Collaboration: Work closely with data scientists, mobile application developers, product owners, and other stakeholders to understand requirements and deliver machine learning solutions.",
      "Performance Optimization: Optimize machine learning models for performance and scalability, including profiling and debugging.",
      "Visualization: Create and maintain dashboards and reports to visualize model outputs and track key performance indicators.",
      "Documentation: Document machine learning processes, models, and analysis results, ensuring transparency and reproducibility.",
      "Continuous Improvement: Stay updated with the latest industry trends and technologies in machine learning and continuously improve development practices.",
      "Testing and Validation: Conduct thorough testing and validation of machine learning models to ensure accuracy and reliability.",
      "Peer Reviews: Participate in peer reviews of machine learning models and code to ensure quality and share best practices.",
    ],

    Perks: [
      "Central Location: Conveniently located in the heart of the city, with parking facilities, and well-served by public transport including buses and Chennai Metro.",
      "Meals and Refreshments: Lunch, tea/coffee, snacks, and refreshments provided throughout the day.",
      "Insurance: TATA AIG Family Group Insurance for INR 5.0 Lakhs (Coverage: Self + Spouse + Up to 3 Children).",
      "Professional Development: Opportunities for continuous learning and growth.",
      "Team Outings and Events: Regular team-building activities and events.",
      "Employee Recognition: Programs to acknowledge and reward outstanding performance.",
    ],
  },


  {
    jobid: "6",
    header: "Human Resources Officer",
    location: "Chennai",
    workHome: "On-Site",
    initialDate: "2024-11-06T00:00:00",
    Discipline: "Technology",
    reporting: "Product Manager",
    exp: "5 Years",
    qual: "Master's degree in business administration or a related field (MBA-HR / MSW-HR)",
    skil: "Programming Languages,Cloud Services",
    linkId: "machine-learning-engineer-at-charles-technologies-4064805228",
    content:
      "Charles Technologies is a dynamic startup based in Chennai, dedicated to creating innovative mobile applications that transform user experiences. We are looking for a talented and experienced Human Resource-Talent Acquisition cum Learning & Development to join our team and lead the learning & Development and Talent Acquisition.",
    requriedexp: [
      "Minimum of 5 years of total experience in Human Resource- Talent Acquisition cum Learning & Development.",
    ],
    qualification: [
      "Master's degree in business administration or a related field (MBA-HR / MSW-HR)",
    ],
    foundationalKnowledge: [
      "Strong understanding of Talent Acquisition and Learning & Development. Proven experience as an L&D Manager or similar role, with a track record of designing and implementing successful training programs.",
    ],
 
    responsePoints: [
      "Source Candidates: Utilize various channels such as social media, job boards, and professional networks to find potential candidates.",
      "Conduct Interviews: Manage the recruitment process, including screening calls, assessments, and interviews.",
      "Maintain ATS: Keep the Applicant Tracking System (ATS) updated with candidate information and recruitment progress.",
      "Employer Branding: Lead initiatives to enhance the company’s employer brand and participate in job fairs and recruitment events.",
      "Onboarding: Facilitate the onboarding process for new hires, ensuring a smooth transition into the company.",
      "Assess Training Needs: Identify training requirements through surveys, interviews, and consultations with managers.",
      "Design Training Programs: Develop and deliver training programs and workshops to improve employee skills and knowledge.",
      "Evaluate Training Effectiveness: Monitor and assess the impact of training programs, making adjustments as needed.",
      "Career Development: Collaborate with department heads to create career development plans for employees.",
      "Monitor Progress: Track and report on training outcomes and employee development.",
      "Stay Updated: Keep abreast of the latest trends and best practices in talent development and incorporate them into training programs."
    ],

    Perks: [
      "Central Location: Conveniently located in the heart of the city, with parking facilities, and well-served by public transport including buses and Chennai Metro.",
      "Meals and Refreshments: Lunch, tea/coffee, snacks, and refreshments provided throughout the day.",
      "Insurance: TATA AIG Family Group Insurance for INR 5.0 Lakhs (Coverage: Self + Spouse + Up to 3 Children).",
      "Professional Development: Opportunities for continuous learning and growth.",
      "Team Outings and Events: Regular team-building activities and events.",
      "Employee Recognition: Programs to acknowledge and reward outstanding performance.",
    ],
  },
];
