import React from "react";
import { Icon } from "@iconify/react";
import callIcon from "../../images/logo/Call.png";
import locationIcon from "../../images/logo/Icon Location.png";
import emailIcon from "../../images/logo/Icon Web Page.png";

export default function ContactInfoWidget({ withIcon, title }) {
  return (
    <>
      {title && <h2 className="cs-widget_title">{title}</h2>}
      <ul className="cs-menu_widget cs-style1 cs-mp0 contactIcon">
        <li>
          {withIcon ? (
            <span className="cs-accent_color">
              <Icon icon="mdi:map-marker" />
            </span>
          ) : (
            ""
          )}
          <img src={locationIcon} />
          4B, Seethakathi Business Centre, #684-690, Anna Salai, Chennai,
          TN-600006, India
        </li>
        <li>
          {withIcon ? (
            <span className="cs-accent_color">
              <Icon icon="mdi:envelope" />
            </span>
          ) : (
            ""
          )}
          <img src={emailIcon} />
          info@charles-technologies.com
        </li>
        <li>
          {withIcon ? (
            <span className="cs-accent_color">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="Layer_1"
                data-name="Layer 1"
                viewBox="0 0 24 24"
                width="30"
                className="cs-accent_color"
                height="30"
              >
                <path
                  d="M15,0h-6c-2.757,0-5,2.243-5,5v14c0,2.757,2.243,5,5,5h6c2.757,0,5-2.243,5-5V5c0-2.757-2.243-5-5-5Zm-2,21h-2c-.552,0-1-.448-1-1s.448-1,1-1h2c.552,0,1,.448,1,1s-.448,1-1,1Z"
                  fill="#be9913"
                />
              </svg>
            </span>
          ) : (
            ""
          )}
          <img src={callIcon} />
          +91 8489925481
        </li>
      </ul>
    </>
  );
}
