import React, { useEffect } from "react";
import PageHeading from "../PageHeading/PageHeading";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
const MAP_URL = process.env.REACT_APP_MAP_URL;

export default function ContactPage() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="cs-google_map mt-4">
        <iframe
          src={MAP_URL}
          width="100%"
          height="100%"
          aria-hidden="false"
        ></iframe>
      </div>
  );
}
