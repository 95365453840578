import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../images/logo/FooterLogo.png";
import { useMyContext } from "../util/context";

export default function Header({ variant,careerRef }) {
  const [isSticky, setIsSticky] = useState(false);
  const [mobileToggle, setMobileToggle] = useState(false);
  const [activeId, setActiveId] = useState("landing");
  const { careerActive } = useMyContext();
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    });
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    const sections = ["home", "careers", "contact", "careershome"];

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveId(entry.target.id);
          }
        });
      },
      { threshold: 0.7 } // Adjust the threshold as needed
    );

    sections.forEach((sectionId) => {
      const section = document.getElementById(sectionId);
      if (section) {
        observer.observe(section);
      }
    });

    return () => {
      sections.forEach((sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
          observer.unobserve(section);
        }
      });
    };
  }, []);
 
  const location = useLocation(); 
  console.log({location})
  useEffect(() => {
    // Scroll to the career section if the hash is "#careers"
    if (location.hash === '#careers' && careerRef.current) {
      console.log({careerRef})
      careerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location])


  const handleScrollToSection = (id) => {
    const element = document.getElementById(id);
    console.log({ element, id });
    
    if (element) {
      console.log(element.id);
      console.log({ element });
      element.scrollIntoView({ behavior: "smooth" });
      setActiveId(id);
    }
  };

  return (
    <>
      <header
        className={`cs-site_header cs-style1 text-uppercase ${
          variant ? variant : ""
        } cs-sticky_header ${isSticky ? "cs-sticky_header_active" : ""}`}
      >
        <div className="cs-main_header">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="cs-main_header_in">
                  <div className="cs-main_header_left">
                    <Link className="cs-site_branding" to="/">
                      <img src={Logo} alt="Logo" />
                    </Link>
                  </div>
                  <div className="cs-main_header_center">
                    <div className="cs-nav cs-tertiary_font cs-medium">
                      <ul
                        className="cs-nav_list"
                        style={{
                          display: `${mobileToggle ? "block" : "none"}`,
                        }}
                      >
                        <li>
                          <Link
                            to="/"
                            onClick={() => handleScrollToSection("landing")}
                            className={
                              activeId === "landing" || activeId === "home"
                                ? "active"
                                : ""
                            }
                          >
                            Home
                          </Link>
                        </li>
                        <li>
                          <Link
                           to="/#careers"
                           
                            className={
                              activeId === "careershome" ||
                              activeId === "careers"
                                ? "active"
                                : ""
                            }
                          >
                            Careers
                          </Link>
                        </li>
                        <li>
                          <Link
                            onClick={() => handleScrollToSection("contact")}
                            className={activeId === "contact" ? "active" : ""}
                          >
                            Contact us
                          </Link>
                        </li>
                      </ul>
                      <span
                        className={
                          mobileToggle
                            ? "cs-munu_toggle cs-toggle_active"
                            : "cs-munu_toggle"
                        }
                        onClick={() => setMobileToggle(!mobileToggle)}
                      >
                        <span></span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
