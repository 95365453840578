import React from "react";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import MenuWidget from "../Widget/MenuWidget";
import TextWidget from "../Widget/TextWidget";
import Logo from "../../images/logo/FooterLogo.png";
import linkdin from "../../images/logo/linkdin.png";
import { useNavigate } from "react-router-dom";

const DUNS = process.env.REACT_APP_DUNS;

export default function Footer({ copyrightText, logoSrc, logoAlt, text }) {

  const navigate = useNavigate()

function linkdinHandler() {
  window.open('https://www.linkedin.com/company/charles-technologies-jcm/', '_blank');
}


  return (
    <footer className="cs-fooer" id="contact">
      <div className="cs-fooer_main ballLightFooter">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div>
                <img src={Logo} alt="Logo" className="logoFooterWidth" />
              </div>
              <button type="button" className="socialLink" onClick={linkdinHandler}>
              <img src={linkdin} width={36}/>  <p>Follow us on <br/> Linkedin</p>
              </button>
            </div>

            <div className="col-lg-5 col-sm-6">
              <div className="cs-footer_item">
                <ContactInfoWidget title="Contact Us" />
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div>
                <iframe
                  id="Iframe1"
                  src={DUNS}
                  width="114px"
                  height="97px"
                  allowtransparency="true"
                ></iframe>
              </div>
            </div>
            <div className="container">
              <div className="cs-bottom_footer  justify-content-center">
                <div className="cs-bottom_footer_left">
                  <div className="cs-copyright">
                    All Rights Reserved @ Charles Technologies
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
