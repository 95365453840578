import React, { createContext, useContext, useState } from "react";


const MyContext = createContext();

// Create a custom hook to access the context
export const useMyContext = () => useContext(MyContext);

// Create a provider component
export const MyContextProvider = ({ children }) => {
  // Define your context state and functions here
  const [careerActive, setCareerActive] = useState(null);
  console.log(careerActive);
  const value = {
    careerActive,
    setCareerActive,
  };

  return <MyContext.Provider value={value}>{children}</MyContext.Provider>;
};
